<template>
  <div class="root">
    <b-loading v-if="loading" v-model="loading" :is-full-page="true" />
    <div v-else id="delivery-reports-main-container">
      <!-- Header -->
      <div id="header" class="flex f-jsb f-ai">
        <div class="flex f-jsb f-ai">
          <div id="logo" class="flex f-jsb f-ai">
            <img src="@/assets/img/logo-light.svg" alt="CFS logo" />
          </div>
          <div>
            <div id="title">{{ $t('deliveries.deliveryStatusReport') }}</div>
            <div>
              <strong>{{ $t('global.orderNumber') }}:</strong>
              {{ data.delivery_info.tracking_number }}
            </div>
            <div v-html="data.delivery_info.created" />
          </div>
        </div>
        <div class="flex f-jsb f-ai" v-html="data.barcode" />
      </div>
      <!-- Delivery information -->
      <div id="delivery-information" class="sections">
        <div class="sections-header">
          {{ $t('deliveries.deliveryInformation') }}
        </div>
        <div class="sections-body">
          <div class="flex f-jsb">
            <div>
              <div class="fields">
                <strong>{{ $t('participant.name') }}</strong>
                <p>{{ data.delivery_info.participant_name || '-' }}</p>
              </div>
              <div class="flex">
                <div class="fields mr">
                  <strong>{{ $t('contact.phone') }}</strong>
                  <p>{{ data.delivery_info.phone_number || '-' }}</p>
                </div>
                <div class="fields">
                  <strong>{{ $t('participant.email') }}</strong>
                  <p>{{ data.delivery_info.email || '-' }}</p>
                </div>
              </div>
              <div class="fields">
                <strong>{{ $t('global.status') }}</strong>
                <div class="flex">
                  <DeliveryIcons
                    class="icons-symbology"
                    isTag
                    :status="{
                      rush: data.delivery_info.rush,
                      priority: data.delivery_info.priority,
                      refrigerated: data.delivery_info.refrigeration_needed,
                      leaveAtDoor: data.delivery_info.leave_at_door,
                      collect: data.delivery_info.delivery_event_type_id === 8
                    }"
                  >
                    <DeliveryStatusSignUI
                      class="m-0"
                      :delivery="{
                        delivery_event_status_id: data.delivery_info.delivery_event_status_id,
                        event_type_is_mandatory: 0
                      }"
                      :isCollect="data.delivery_info.delivery_event_type_id === 8"
                    />
                  </DeliveryIcons>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="fields">
                <strong>{{ $t('contact.address') }}</strong>
                <p>{{ data.delivery_info.full_address || '-' }}</p>
              </div>
              <div class="fields">
                <strong>{{ $t('dispatch.driverName') }}</strong>
                <p>{{ data.delivery_info.employee_name || '-' }}</p>
              </div>
              <div>
                <strong>{{ $t('global.notes') }}</strong>
                <p>{{ data.delivery_info.notes || '-' }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Timeline information -->
      <div id="timeline-information" class="sections">
        <div class="sections-header">
          {{ $t('deliveries.report.timelineInfo') }}
        </div>
        <div class="sections-body">
          <!-- Transitions by date -->
          <div v-for="(event, index) in data.timeline_info" :key="'timeline-info-' + index">
            <strong class="subTitle flex f-jc"
              ><DateTime :date="event.date" format="MM/DD/Y"
            /></strong>
            <div v-for="(transition, i) in event.transitions" :key="'transitions-' + i">
              <div class="transitions flex f-jsb">
                <div>
                  <div class="flex f-jsb">
                    <div class="flex">
                      <strong class="mr-1">
                        <DateTime
                          :date="transition.datetime"
                          :offset="transition.time_offset"
                          format="hh:mm A"
                        />
                      </strong>
                      <p class="has-text-weight-bold">-</p>
                      <strong class="ml-1">{{
                        statusName(transition.delivery_event_status_id)
                      }}</strong>
                    </div>
                  </div>
                  <div class="flex">
                    <p>
                      <span class="collect-method" v-if="transition.id === 0">
                        {{ transition.copayment_method }}
                      </span>
                      {{ getMessage(transition) }}
                    </p>
                    <p v-if="needsReason(transition.delivery_event_status_id)" class="ml-1">
                      - {{ $t('global.reason') }}: {{ getReason(transition) }}
                    </p>
                  </div>
                  <div v-if="transition.previous_participant_full_address">
                    <p>
                      {{ $t('address.previous') }}:
                      {{ transition.previous_participant_full_address }}
                    </p>
                  </div>
                </div>
                <CopaymentTag
                  v-if="transition.id === 0"
                  :value="transition.copayment_amount"
                  :sufix="undefined"
                />
                <DeliveryStatusSignUI
                  v-else
                  :delivery="transition"
                  :isCollect="data.delivery_info.delivery_event_type_id === 8"
                />
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>

      <!-- Return Info -->
      <div id="return-proof" class="sections" v-if="data.delivery_info.return_manifest_id">
        <div class="sections-header">
          {{ $t('deliveries.report.returnInfo') }}
        </div>
        <div class="sections-body flex f-jc f-column">
          <div class="flex">
            <div class="fields mr">
              <strong>{{ $t('deliveries.report.returnHour') }}</strong>
              <DateTime
                :date="returnInfo.return_created_at"
                :offset="returnInfo.time_offset"
                format="hh:mm A"
              />
            </div>
            <div class="fields">
              <strong>{{ $t('deliveries.report.returnDestination') }}</strong>
              <p class="mb-2">{{ cdp_address || '-' }}</p>
            </div>
          </div>
          <div class="fields signature">
            <strong>{{ $t('global.signature') }}</strong>
            <Picture
              v-if="
                returnInfo.return_signature &&
                ![6, 7, 16, 11, 17].includes(data.delivery_info.delivery_event_status_id)
              "
              :src="'data:image/png;base64,' + returnInfo.return_signature"
            />
            <SignatureError v-else :deliveryId="data.delivery_info.delivery_event_status_id" />
          </div>
        </div>
      </div>

      <!-- Photo & signature -->
      <div id="delivery-proof" class="sections" v-if="data.path_evidence || signatureExists">
        <div class="sections-header">
          {{ $t('deliveries.report.photoSignature') }}
        </div>
        <div class="sections-body flex f-jsb">
          <div class="section-container">
            <div class="fields mr-5">
              <strong>{{ $t('global.notes') }}</strong>
              <p class="mb-2">{{ data.delivery_info.notes || '-' }}</p>
              <Picture v-if="data.path_evidence" :src="data.path_evidence" />
            </div>
          </div>
          <div class="section-container">
            <div class="fields">
              <strong>{{ $t('deliveries.recipientName') }}</strong>
              <p class="mb-2">{{ data.delivery_info.receptor_name || '-' }}</p>
              <Picture
                v-if="
                  signatureExists &&
                  ![6, 7, 16, 11, 17].includes(data.delivery_info.delivery_event_status_id)
                "
                :src="'data:image/png;base64,' + data.delivery_info.signature"
              />
              <SignatureError v-else :deliveryId="data.delivery_info.delivery_event_status_id" />
            </div>
          </div>
        </div>
      </div>

      <!-- Map info -->
      <div
        id="map-info"
        v-if="
          data.delivery_info.delivery_event_status_id != 1 &&
          data.delivery_info.delivery_event_status_id != 5 &&
          data.locations &&
          data.locations.length > 0
        "
        class="sections"
      >
        <div class="sections-header">
          {{ $t('deliveries.report.mapInfo') }}
        </div>
        <div class="sections-body">
          <div class="flex">
            <div class="fields mr">
              <strong>{{ $t('deliveries.report.totalDistance') }}</strong>
              <p>{{ miles ? `${miles} ${$t('global.miles')}` : '-' }}</p>
            </div>
            <div class="fields">
              <strong>{{ $t('deliveries.report.timeTaken') }}</strong>
              <p>{{ minutes ? `${minutes} ${$t('global.minutes').toLowerCase()}` : '-' }}</p>
            </div>
          </div>
          <DeliveryReportMap
            :locations="data.locations"
            :endLocation="endLoc"
            :statusId="data.delivery_info.delivery_event_status_id"
          />
          <div class="symbology flex f-ai mt-3 mb-2 p-4">
            <strong class="mr-2">{{ $t('global.symbology').toUpperCase() }}:</strong>
            <div v-for="(marker, i) in markers" :key="'marker' + i" class="flex f-jc f-ai">
              <b-image :src="require(`@/assets/img/markers/marker-${marker.icon}.png`)" />
              <p class="ml-2 mr-4">{{ marker.label }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DateTime,
  DeliveryStatusSignUI,
  Picture,
  DeliveryIcons,
  SignatureError
} from '@/components';
import DeliveryReportMap from './Map/DeliveryReportMap.vue';
import { getTypeData, eventStatusAsEventType, _eventStatus } from './DeliveryTypes';
import { CopaymentTag } from '..';

export default {
  components: {
    DateTime,
    DeliveryReportMap,
    DeliveryStatusSignUI,
    Picture,
    DeliveryIcons,
    SignatureError,
    CopaymentTag
  },
  mounted() {
    this.getReportData();
  },
  data() {
    const paramsEventId = Number(this.$route.params.eventId || 0);
    return {
      cdp_address: '',
      data: { delivery_info: {}, timeline_info: [], locations: [], map_info: {} },
      deliveryReport: null,
      endLoc: {},
      loading: false,
      paramsEventId
    };
  },
  computed: {
    markers() {
      return [
        { label: this.$t('global.start'), icon: 'start' },
        { label: this.$t('global.end'), icon: 'end' },
        { label: this.$t('global.currentLocation'), icon: 'current' }
      ];
    },
    miles() {
      const meters = this.data.map_info.distance_meters;
      return meters ? (meters * 0.00062137).toFixed(2) : null;
    },
    minutes() {
      const seconds = this.data.map_info.time_taken_seconds;
      return seconds ? Math.round(seconds / 60) : null;
    },
    returnInfo() {
      let returnedTransition = this.data.timeline_info
        .map((timeline) =>
          timeline.transitions.filter((transition) => transition.return_manifest_id)
        )
        .filter((el) => el.length)[0];
      returnedTransition = returnedTransition.length ? returnedTransition[0] : {};
      return this.data.delivery_info.return_manifest_id ? returnedTransition : {};
    },
    signatureExists() {
      const { signature } = this.data.delivery_info;
      return signature && (signature || '').toUpperCase() !== 'X';
    }
  },
  methods: {
    isFinalState(id) {
      return [4, 6, 7, 11].includes(id); // Delivered, Rejected, Canceled, Failed
    },
    useEventTypes(delivery) {
      const { event_type_is_mandatory, delivery_event_type_id } = delivery;
      return (
        event_type_is_mandatory || !!(eventStatusAsEventType.indexOf(delivery_event_type_id) + 1)
      );
    },
    statusName(status_id) {
      return _eventStatus(status_id)?.name;
    },
    getStatus(delivery) {
      const useEventTypes = this.useEventTypes(delivery);
      const { delivery_event_status_id, delivery_event_type_id } = delivery;
      let id = useEventTypes ? delivery_event_type_id : delivery_event_status_id;
      const status = getTypeData({ id, useEventTypes: useEventTypes });
      return status.actionMsg;
    },
    getMessage(transition) {
      let status = this.getStatus(transition);
      let msg = '';
      if (transition.delivery_event_status_id == 6) {
        msg = transition.receptor_name ? transition.receptor_name : '';
      } else {
        msg = `${
          transition.created_by_name
            ? transition.created_by_name
            : this.$t('global.driver').toUpperCase()
        } ${
          transition.receptor_name
            ? this.$t('deliveries.to').toLowerCase() + ' ' + transition.receptor_name
            : ''
        }`;
      }
      return msg ? `${status} ${msg}` : '';
    },
    getReason(transition) {
      if (!transition.non_delivery_reason_description) return '-';
      return transition.non_delivery_reason_id === 4
        ? transition.other_non_delivery_reason_description
        : transition.non_delivery_reason_description;
    },
    async getCDP() {
      this.loading = true;
      try {
        const { data } = await this.Api.get(`cdp/${this.returnInfo.return_destination_id}`);
        this.cdp_address = data.full_address;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    async getReportData() {
      this.loading = true;
      try {
        const { data } = await this.Api.post(
          `delivery_manifest/reports/pdf?event_id=${this.paramsEventId}`
        );
        this.data = this.formatCreatorInfo(data);
        this.endLoc = { lat: data.delivery_info.latitude, lng: data.delivery_info.longitude };
        data.barcode = data.barcode.replace(new RegExp("b\\b|\\b'", 'gi'), '');
        data.barcode = data.barcode.replace(/\\n/g, '');
        if (data.delivery_info.return_manifest_id) this.getCDP();
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    formatCreatorInfo(data) {
      const latestTransition = data.timeline_info[0].transitions.find(
        (transition) => transition.id === -1
      );

      if (!latestTransition) return data;

      const creatorName = latestTransition.created_by;
      const formattedDate = this.$moment(latestTransition.created_at).format('DD/MM/YYYY HH:mm A');

      data.delivery_info.created = `${this.$t('global.createdBy')}: 
      <strong>${creatorName}</strong> ${this.$t('global.at')} <strong>${formattedDate}</strong>`;

      data.timeline_info[0].transitions = data.timeline_info[0].transitions.filter(
        (transition) => transition.id !== -1
      );

      return data;
    },
    needsReason(eventStatusId) {
      return [5, 6, 7, 11].includes(eventStatusId); // Rescheduled, Rejected, Canceled, Failed
    }
  }
};
</script>
<style lang="sass" scoped>
@media print
  .root
    background: white
  #delivery-reports-main-container
    overflow: visible !important
    -webkit-print-color-adjust: exact
    #delivery-proof
      page-break-before: always
    #map-info
      page-break-before: always
    ::v-deep
      .Picture
        // height: 150px!important
.root
  height: 100vh
  background: $gray-800
#delivery-reports-main-container
  max-width: $bp-lg
  margin: 0 auto
  padding: 40px
  overflow-y: auto
  background: white
  height: 100%
  #title
    font-weight: bold
    font-size: $f-xl
  .subTitle
    font-weight: bold
    color: $gray-800
  hr
    height: 1px
    margin: .5rem 0
    background-color: $gray-200
  #header
    width: 100%
    height: 80px
    color: $dark
    font-size: $f-xs
    #logo
      margin-right: 30px
      img
        width: 90px
  .collect-method
    color: $gray-800
    font-weight: 500
  .icons-symbology
    margin-top: 0.4rem
    ::v-deep .delivery-tag
      height: 100%
      margin-bottom: 0
  .sections
    width: 100%
    margin-top: 20px
    font-size: $f-xs
    border-radius: $br-sm
    border: 1px solid $gray-200
    &#delivery-proof, &#return-proof
      .section-container
        width: 50%
      ::v-deep .Picture
        border-radius: 0
        .pe-container.loaded
          position: relative
        .letters
          display: none
        .image img
          width: auto
    .signature
      display: flex
      flex-direction: column
      .signature-error
        width: 120px
    .sections-header
      padding: 5px
      color: $gray-800
      font-weight: 700
      text-align: center
      border-bottom: 1px solid $gray-200
      background-color: $main-background
    .sections-body
      padding: 20px 20px 10px 20px
      .symbology
        border-radius: $br-sm
        border: 1px solid $gray-200
      .right
        max-width: 250px
      .fields
        margin-bottom: 10px
      ::v-deep
        .tag
          padding: 15px
          font-size: $f-xs
</style>
