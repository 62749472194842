<template>
  <Delivery-Report />
</template>

<script>
import DeliveryReport from '@/components/Dispatch/DeliveryReport.vue';

export default {
  components: {
    DeliveryReport
  }
};
</script>
