<template>
  <section>
    <div id="content" class="flex">
      <gmapMap
        ref="DRmapRef"
        id="map"
        :center="oMap.center"
        :zoom="oMap.zoom"
        style="height: 100%"
        :options="mapStyle"
      >
        <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :icon="m.icon ? require(`@/assets/img/markers/${m.icon}.png`) : ''"
        />
        <gmap-polyline ¿ :path="path" :options="{ strokeColor: '#9BA7FB' }" />
      </gmapMap>
    </div>
  </section>
</template>

<script>
import { map_apiKey } from '@/config/constants.js';
import { lightMapStyle } from '@/utils/MapStyles';
import * as VueGoogleMaps from 'vue2-google-maps';
import Vue from 'vue';
Vue.use(VueGoogleMaps, { load: { key: map_apiKey } });

export default {
  async mounted() {
    this.map = await this.$refs.DRmapRef.$mapPromise;
    this.getLocationsOnMap(this.locations);
  },
  data() {
    return {
      isHidden: true,
      map: null,
      oMap: { center: { lat: 36.610423, lng: -119.928424 }, zoom: 8 },
      markers: [],
      path: []
    };
  },
  computed: {
    mapStyle() {
      return {
        styles: lightMapStyle,
        options: {
          clickableIcons: false,
          draggable: false,
          disableDefaultUi: true,
          disableDoubleClickZoom: true,
          fullscreenControl: false,
          mapTypeControl: false,
          rotateControl: false,
          scaleControl: false,
          streetViewControl: false,
          zoomControl: false
        }
      };
    }
  },
  methods: {
    autoZoom(path, lessZoom) {
      if (path?.length) {
        const { map } = this;
        if (map && path.length) {
          let latlngbounds = new window.google.maps.LatLngBounds();
          path.map((path) => (latlngbounds = this.maplatLngExtendPath(latlngbounds, path)));
          map.fitBounds(latlngbounds);
          const currentZoom = map.getZoom();
          if (currentZoom > 18) map.setZoom(18);
          if (lessZoom) map.setZoom(currentZoom - lessZoom);
        }
      }
    },
    maplatLngExtendPath(latlngbounds, paths) {
      if (paths.lat) {
        return latlngbounds.extend(paths);
      } else {
        paths.map((path) => {
          latlngbounds = this.maplatLngExtendPath(latlngbounds, path);
        });
        return latlngbounds;
      }
    },
    getLocationsOnMap(args) {
      const start = { lat: args[0].latitude, lng: args[0].longitude };
      const current = { lat: args[args.length - 1].latitude, lng: args[args.length - 1].longitude };
      this.markers.push({ position: start, icon: 'marker-start' });
      if (this.endLocation) this.markers.push({ position: this.endLocation, icon: 'marker-end' });
      if (!this.endLocation || ![4, 6, 7, 11].includes(this.statusId))
        this.markers.push({ position: current });
      args.map((loc) => {
        this.path.push({ lat: loc.latitude, lng: loc.longitude });
      });
      this.path.push(this.endLocation);
      this.autoZoom(this.path);
    }
  },
  props: {
    locations: {
      type: Array,
      default: () => []
    },
    endLocation: {
      type: Object,
      default: () => {}
    },
    statusId: {
      type: Number
    }
  }
};
</script>
<style lang="sass" scoped>
#content
  height: 50%
  height: calc(50vh - 80px)
#map
  width: 100%
  height: 100%
</style>
